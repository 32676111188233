
.skills{
  display: flex;
  flex-direction: column;
  width: 95vw;
  flex-wrap: wrap;
  margin: auto;
  
}


.skills p{
  display: flex;
  width: 95vw;
  flex-wrap: wrap;
  justify-content: flex-start;

  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;

  color: rgb(160, 167, 173);
  margin-top: -10px;
 text-align: justify;
 line-height: 1.5;



}
.skills .oop{

 
  align-items: center;
  width: 95vw;
  border: 2px solid rgba(115, 19, 159, 0.836);
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 2px;

  background-color: rgba(54, 7, 130, 0.626);

  color: white;
}

.skills .backend{
  

  align-items: center;
  border: 2px solid rgba(115, 19, 159, 0.836);
  margin-top: 30px;
  width: 95vw;

  margin-left: 2px;


  border-radius: 10px;

  background-color: rgba(54, 7, 130, 0.678);
  color: white;
}
.skills .frontend{


  align-items: center;
  border: 2px solid rgba(115, 19, 159, 0.836);
  margin-top: 30px;
  width: 95vw;

  margin-left: 2px;

  border-radius: 10px;

  background-color: rgba(54, 7, 130, 0.678);
  color: white;
}

.skill-card{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: auto;


  

}
.skill-card:hover{
  cursor: pointer;
  scale: 1.5;

  
}


@media screen and (min-width:768px){
  .skills{
    display: block;
  
    width: 95vw;
    flex-wrap: wrap;
  }
 .skills .icons{
  display: flex;
  flex-wrap: wrap;
  margin-left: 400px;
  gap: 2rem;




} 

.skills .oop{
  display: flex;

  width: 95vw;

}

.skills .backend{
  display: flex;

  width: 95vw;
}
.skills .frontend{
  display: flex;
  width: 95vw;
}
.skills p{
  display: flex;
  font-weight: bolder;
  width: 70vw;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;

  margin-top: -10px;
  text-align: justify;
 
}


}
.footer{
    display: flex;
  
    width: 95vw;
    height:200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(157, 165, 166);
    gap: -0.5rem;
    margin-top: 70px;
}
.project-card{
    display: flex;
    flex-wrap: wrap;

    width: 350px;
    height: 450px;
    justify-content: center;
    margin-top: 20px;

  
  
}
a{
    background-color: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708)) !important;
}
a:visited{
    color: white;
}

.project-card-icon{
    display: none;
   
}

.project-card:hover .project-card-icon{
    display: flex !important; 
    position: absolute;
    right: 95px;
    margin-top: 220px;
    scale: 2;
    gap: 1rem;
    color: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708)) !important;
    text-decoration: none !important ;

   
}




.project-card .project-card-image{
    border: 2px solid;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width:260px ;
    height: 200px;
   margin-bottom: 500px;

   border-radius:10px ;
  
}

.project-card .project-card-image img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}



.project-card .project-card-info{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    height: 350px;
    margin: auto;
    border: 1px solid;
    margin-bottom: 0px;
    border-radius: 10px;
    background-color: #28173aa2;
    border: 2px solid #5f0b6cd9;
}
.project-card .project-card-info h1{
    background: linear-gradient(190deg,rgb(47, 208, 240),rgb(231, 135, 83));
    font-size: larger;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 10px;
    margin-top: 130px;
  
}
.project-card .project-card-info p{
    margin-top: -10px;
    margin-left: 10px;
    font-size: 15px;
  
    color: rgba(240, 238, 235, 0.925);
    font-weight: bold;
    
}

@media screen and (min-width: 768px) {
    .project-card{
        display: flex;
        flex-wrap: wrap;
      
        width: 600px;
        height: 250px;
        justify-content: e;
      
        margin-left: 50px;      
      
    }
    .project-card .project-card-icon{
        display: none
      
    }
    .project-card:hover .project-card-icon{
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 40px;
        margin-top: 20px;
        scale: 2.5;
        gap: 1rem;
        color: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708)) !important;
  
        
        
        
    }
    .project-card .project-card-image{
       
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        width:260px ;
        height: 200px;
        margin-top: 20px;
       margin-left: -350px;
       background-color: black;
       border-radius:10px ;
    }
    .project-card .project-card-info{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
   
        width: 400px;
        height: 250px;
      margin-left: 200px;
        
        margin-bottom: 0px;
        border-radius: 10px;
        background-color: #28173aa2;
        border: 2px solid #5f0b6cd9 ;
    }
    .project-card .project-card-info h1{
        margin-left: 60px;
        margin-top: 10px;
        font-size: 30px;
       
    }
    .project-card .project-card-info p{
        margin-left: 60px;
        padding: 6px;
        font-size: large;
        
       
    }
    
}
.hero{
display: block;
    width: 95vw;
    height: 100%;
}
.hero-top{

    display: flex;
    width: 97vw;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    margin-top: 50px;

   
}
.hero-top h2{
    
    width: 50vw;
    margin-left: -100px;
    margin-bottom: -2px;
    font-size: 30px;
    color: rgba(168, 175, 182, 0.774);
    
}
.hero-top h1{
  
    font-size:70px;
    color: transparent;
    margin-top: -10px;
        /* background-image: url(/src//assets/Frame\ 1.png); */
        background-color: aqua;
        -webkit-background-clip: text;
      background-position: 10 0;
      animation: back 19s linear infinite;
      font-family: 'Days One', sans-serif;
}
@keyframes back {
    100%{
      background-position: 2000px 0;
    }
    
  }
.hero-top p{
   
    width: 80vw;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
   margin: auto;
    margin-top: -50px;
   
}
.hero-bottom{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 400px;
    margin-top: -10px;

    
}
.hero-top button{
    font-family: 'Poppins', sans-serif;
    width: 200px;
    height: 50px;
    margin-top: 10px;
    color:white;
    background: linear-gradient(108deg, rgb(58, 177, 232), rgb(176, 66, 220));
    font-weight: bold;
    border-radius:6px ;
    
}
.hero-top a{
    text-decoration: none;
}

.linkedin{
    display: flex;
    scale: 3;
    margin-top: 25px;
    margin-left: -70px;
    background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
    color: #090527 !important;
    
   
}
.github{
    display: flex;
    scale: 3;
    margin-left: 70px;
    margin-top: -15px;
    background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
    border-radius: 100%;
    color: #090527 !important;
   
}

@media screen and (min-width:1024px) {

    .hero{
        display: flex;
        flex-direction: row;
        width: 98vw;
        height: 100vh;
    }
    .hero-left{
        width: 50vw;
        display: flex;
        flex:1;
        flex-direction: column;
    
       
    }
    .hero-right{
        display: flex;

        width: 48vw;
       
        height: 700px;
    }
    .hero-left h2{
        display: flex;
        position: absolute;
        font-size: 50px;
        margin-bottom: 90px;
        margin-left: 110px;
        color: rgba(168, 175, 182, 0.774);
       
    }
    .hero-left h1{
        display: flex;
        position: absolute;
        color: transparent;
        margin-top: 250px;
        margin-left: -60px;
        font-size: 140px;
        /* background-image: url(/src/assets/Frame\ 1.png); */
        background-color: aqua;
        -webkit-background-clip: text;
  
      background-position: 10 0;
      animation: back 0s linear infinite;
    
    }
    @keyframes back {
        100%{
          background-position: 2000px 0;
        }
        
      }
    .hero-top p{
        font-family: 'Montserrat', sans-serif;       
        text-align: justify;
        margin-top: 380px;
        margin-left: 30px;
    }
    .hero-right{
        display: flex;
        flex: 1;
    }
    .hero-left button{
        display: flex;
        margin-left: -350px;
        
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color:white;
        background: linear-gradient(108deg, rgb(58, 177, 232), rgb(176, 66, 220));
        font-weight: bold;
    }
    .hero-top button:hover{
        cursor: pointer;
        transform: translateY(-5px);
        background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
       
    }
    .linkedin{
        display: flex;
        scale: 2.8;
        margin-left: -200px;
        margin-top: -35px;
        background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
        color: #090527 !important;
        
       
    }
    .github{
        display: flex;
        scale: 3;
        margin-left: -80px;
        margin-top: -15px;
        background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
        border-radius: 100%;
        color: #090527 !important;
       
    }
    .linkedin:hover{
        transform: translateY(-2px);
    }
    .github:hover{
        transform: translateY(-2px);
    }
    
}


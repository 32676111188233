
.projects{
    display: flex;
    flex-direction: column;
    width: 95vw;
    height: 100%;
    flex-direction: column;
   
    

}
.projects .project-cards{
    display: flex;
    flex-wrap: wrap;
    width: 95vw;
  justify-content: center;
  align-items: center;
}


@media screen and (min-width:768px) {
    .projects{
        width: 95vw;
       display: flex;
       flex-wrap: wrap;
       margin-bottom: 20px;
       padding: 15px;
       
    }
    .projects .title{
        width: 95vw;
    }
    .projects .project-cards{
        display: flex;
        flex-wrap: wrap;
      width: 95vw;
      justify-content: center;
      align-items: center;
    }
}

.wrapper{

    border: 2px solid rgba(9, 31, 105, 0.733);
    background-color: #290a4a86;

    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;

}
.contact{
    display: block;
    width: 95vw;
    height: 100vh;
    margin-bottom: 20px;
    
 
}
.contact .planet{
    display: flex;
    flex: 1;

    width: 95vw;
    height: 50vh;
    margin: auto;

  
}
.contact .contact-right{
    display: block;
    flex: 1;

    justify-content: center;
    align-items: center;
    margin-top: 100px;
  
    
}

.contact .contact-right{
    width: 95vw;
    height: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}
.contact .contact-right .greetings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
   
}
.contact .contact-right .greetings p{
    color: rgb(188, 196, 202);
    font-weight: bolder;
    width: 80vw;
    font-size: medium;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;

   
   
}
.contact .contact-right .greetings h1{
    background: linear-gradient(190deg,rgb(0, 255, 136),rgb(0, 242, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;
}
.contact .contact-right .forms form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 20px;

}
.contact .contact-right .forms form input{
    width: 85vw;
    height: 40px;

    border-radius: 10px;
    padding-left: 10px;

}
.contact .contact-right .forms form textarea{
    width: 85vw;
    height: 70px;
    resize: none;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 10px;
}
.contact .contact-right .forms form button{
    width: 100px;
    height: 40px;
    color:white;
    background: linear-gradient(108deg, rgb(58, 177, 232), rgb(176, 66, 220));
    font-weight: bold;
    border-radius:8px ;
    font-family: 'Poppins', sans-serif;

}
.contact .contact-right .forms form button:hover{
    cursor: pointer;
}
.contact .contact-right p{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}

.mail-alert{
    background-color: rgba(57, 155, 57, 0.826);
    border: 1px solid;
    padding: 6px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    
}
.mail-alert .tick{
    
    scale: 1.5;
    padding: 2px;
    background-color: rgba(78, 163, 33, 0.947);
    
}
.mail-alert .sent-alert{
    margin-left: 10px;
}

@media screen and (min-width: 1440px) {
    .contact{
        display: flex;
        width: 95vw;
        height: 100vh;
      
   
    }
    .contact .contact-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
   
        margin-top: -3px;
    
   
    }
   
    .contact .planet{
        display: flex;
        flex: 1;
        width: 700px;
        height: 700px;
      
      
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        
        margin-top: -3px;
  
    }
    .contact .contact-right .greetings{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact .contact-right .forms form{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    
    }
    .contact .contact-right .forms form input{

        height: 50px;
        width: 600px;
        border-radius: 10px;
    }
    .contact .contact-right .forms form textarea{
        height: 90px;
        width: 600px;
        border-radius: 10px;
        resize: none;
    }
    .contact .contact-right .forms form button{
        height: 50px;
        width: 150px;
       
    }
    .contact .contact-right .greetings p{
        color: rgb(188, 196, 202);
        font-weight: bolder;
        width: 500px;
        font-size: large;
        font-family: 'Montserrat', sans-serif;
       
    }
    .wrapper{

        border: 2px solid rgba(9, 31, 105, 0.733);
        background-color: #290a4a86;
        padding: 20px;
        border-radius: 20px;
    }
    .contact .contact-right .forms form button:hover{
        cursor: pointer;
        transform: translateY(-5px);
        background: linear-gradient(108deg, rgba(58, 177, 232, 0.635), rgba(176, 66, 220, 0.708));
       
    }
}
body{
    background-color: #090527;
    color: white;
    font-family: 'Montserrat', sans-serif;
}
li{
    font-family: 'Poppins', sans-serif;
    color: white;
}

.navbar{
    display: flex;


    
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.1);
   width: 98vw;

   top:0;

    color: white;

    background-color: #0e0547e8;
}
.navbar .logo{
    display: flex;

    justify-content: center;
    align-items: center;
    margin-left: 10px;
    
}

.navbar button{
    border: none;
    scale: 2;
    margin-top: -1px;
    background-color: #0e0547e8;;
    height: 30px;
   color: white;
   margin-right: 20px;
   width: 15px;
}
.navbar .menu{
    justify-content: center;
    align-items: center;
    display: flex;
 
}
.navbar .menu .desktop-menu{
    margin-right: 50px;
}

.navbar .menu .desktop-menu ul li{
    list-style: none;
    color: white ;
   
}
.navbar .menu .desktop-menu ul{
   
    display: flex;
    justify-content: space-evenly;
    gap: 8rem;
 
}
.navbar .menu .desktop-menu ul li:hover{
    cursor: pointer;
    color: rgb(1, 254, 254);
}
li::after{
    content: "";
    position: absolute;
    background: linear-gradient(100deg, rgb(9, 247, 251), rgb(225, 0, 254)); 
    height: 2px;
    width: 0;
    margin-top: 25px;
    margin-left: -20px;
    transform: translateX(-65%);
    transition: width 0.3s;
 
   
}
li:hover::after{

width:  60px;
}


.mobile-menu{
    display: flex;
    
    position: absolute;
    top:70px;
    right: 10px;
    background-color: #0c1768f0;
    z-index: 9999;
    height: 100vh;
    width: 250px;
    justify-content: space-evenly;
    align-items: flex-start;
    right: 0;

}
.mobile-menu ul {
  
    border-radius:10px ;
    width: 100px;
    
    padding: 20px;
 
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 5rem;

    
    
}
.mobile-menu ul li{
    list-style: none;
  

  
}
 